<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col md="9">
        <collection-image-show :id="id"></collection-image-show>
      </b-col>
      <b-col md="3">
        <item-list-card
          :data_item="item"
          :mappings="association_items"
          :included="association_included"
        ></item-list-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-button block variant="primary" @click="callDone">Done</b-button>
      </b-col>
      <b-col md="6">
        <b-button block variant="primary" @click="callUpdate">Update</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import CollectionImageShow from './CollectionImageShow.vue'
import ItemListCard from './ItemListCard'

export default {
  components: {
    VuexBreadcrumb,
    CollectionImageShow,
    ItemListCard
  },
  props: {
    id: String
  },
  data () {
    return {
      ams_type: 'collection-images',
      attr_copy: {},
      attr_orig: {},
      item: {},
      association_items: [],
      association_included: [],
    }
  },
  methods: {
    callDone() {
      this.$router.go(-1)
    },
    callUpdate() {
      this.$router.push( {path: '/' + this.ams_type + '/' + this.id + '/update'})
    },
    getItems () {
      http_v1.get (
        'api/admin/v1/item_image_associations', {
          params: {
            collection_image_ids: this.id,
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.association_items = response.data.data
          this.association_included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.association_items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callApi () {
      http_v1.get('api/admin/v1/collection_images_with_product_items/' + this.id, {
      }).then(response => {
        if( response.data) {
          this.attr_copy = Object.assign( {}, response.data.data.attributes)
          this.attr_orig = Object.assign( {}, response.data.data.attributes)
          this.item = Object.assign( {}, response.data.data )
          this.included = response.data.included
          this.getItems()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  mounted () {
    this.callApi()
  }
}
</script>