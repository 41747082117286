<template>
 <div>
  <b-row align="center">
     <b-col>
      <b>{{description}}</b>
     </b-col>
   </b-row>
   <b-img thumbnail fluid
    :src="ams_data.attributes['image-url']"
    :alt="description"
    >
    </b-img>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {belongsToDescription} from '@/utils'
// import AmsCard from './AmsCard.vue'

export default {
  // components: {
  //   AmsCard
  // },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      ams_type: 'collection-images',
      ams_api_path: 'api/admin/v1/',
      ams_data: {},
      description: undefined,
      describe_belongs_to: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ' '
        }
      ]
    }
  },
  methods: {
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_') + '/' + this.id
    }
  },
  mounted () {
    http_v1.get(this.apiPath(), {
    }).then(response => {
      if( response.data) {
        this.ams_data = response.data.data
        this.description = belongsToDescription(
          response.data.data, response.data.included, this.describe_belongs_to
        )
        this.$emit('gotAttrs', this.ams_data?.attributes, this.description)
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>